// extracted by mini-css-extract-plugin
export var breadcrumbContainer = "ProviderLocalityHeader__breadcrumbContainer__aQJu_";
export var column = "ProviderLocalityHeader__column__CJjA2";
export var downloadIcon = "ProviderLocalityHeader__downloadIcon__uzpN9";
export var dropdownBody = "ProviderLocalityHeader__dropdownBody__R98C6";
export var dropdownBodyRow = "ProviderLocalityHeader__dropdownBodyRow__p2Jvj";
export var dropdownTrigger = "ProviderLocalityHeader__dropdownTrigger__YfdZk";
export var editIcon = "ProviderLocalityHeader__editIcon__x5aNL";
export var filtersContainer = "ProviderLocalityHeader__filtersContainer__GEkvy";
export var flex = "ProviderLocalityHeader__flex__t2RP9";
export var flexColumn = "ProviderLocalityHeader__flexColumn__DTzbG";
export var gap1 = "ProviderLocalityHeader__gap1__JjIgS";
export var gap2 = "ProviderLocalityHeader__gap2__oEfoR";
export var gap3 = "ProviderLocalityHeader__gap3__PhzhY";
export var gap4 = "ProviderLocalityHeader__gap4__nLLTk";
export var gap5 = "ProviderLocalityHeader__gap5__KUeEu";
export var headerContainer = "ProviderLocalityHeader__headerContainer__GdaoN";
export var label = "ProviderLocalityHeader__label__cLnGe";
export var locationsCount = "ProviderLocalityHeader__locationsCount__FNc5o";
export var providersDropdown = "ProviderLocalityHeader__providersDropdown__hmzSs";
export var providersSearch = "ProviderLocalityHeader__providersSearch__Bg1jv";
export var reset = "ProviderLocalityHeader__reset__YLDvj";
export var row = "ProviderLocalityHeader__row__GRjXE";
export var searchField = "ProviderLocalityHeader__searchField__Bwzr0";
export var title = "ProviderLocalityHeader__title__IzD41";
export var titleContainer = "ProviderLocalityHeader__titleContainer__MTxHU";