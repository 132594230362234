// extracted by mini-css-extract-plugin
export var aboutContainer = "ProviderLocalityShowContent__aboutContainer__gvVrG";
export var column = "ProviderLocalityShowContent__column__xIYYw";
export var contentContainer = "ProviderLocalityShowContent__contentContainer__UpoKN";
export var flex = "ProviderLocalityShowContent__flex__hQ4gq";
export var flexColumn = "ProviderLocalityShowContent__flexColumn__zvlwh";
export var gap1 = "ProviderLocalityShowContent__gap1__E3vkP";
export var gap2 = "ProviderLocalityShowContent__gap2__OL0Hu";
export var gap3 = "ProviderLocalityShowContent__gap3___o0mf";
export var gap4 = "ProviderLocalityShowContent__gap4__uk_HF";
export var gap5 = "ProviderLocalityShowContent__gap5__w6TlX";
export var h2 = "ProviderLocalityShowContent__h2__EQvab";
export var localitiesSubtitle = "ProviderLocalityShowContent__localitiesSubtitle__tgZLv";
export var locationsContentContainer = "ProviderLocalityShowContent__locationsContentContainer__hjVcW";
export var row = "ProviderLocalityShowContent__row__wpOS8";
export var serviceCategory = "ProviderLocalityShowContent__serviceCategory__VkqrX";
export var stat = "ProviderLocalityShowContent__stat__XY86H";
export var statName = "ProviderLocalityShowContent__statName__B0BxI";
export var statNumber = "ProviderLocalityShowContent__statNumber__pf42H";
export var statsContainer = "ProviderLocalityShowContent__statsContainer__f3ZXJ";
export var sticky = "ProviderLocalityShowContent__sticky___a5S2";
export var topProvidersContainer = "ProviderLocalityShowContent__topProvidersContainer__ShDrm";