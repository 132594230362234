// extracted by mini-css-extract-plugin
export var column = "ProviderLocalityShowStates__column__JCN8z";
export var flex = "ProviderLocalityShowStates__flex__GHU10";
export var flexColumn = "ProviderLocalityShowStates__flexColumn__e_vjF";
export var gap1 = "ProviderLocalityShowStates__gap1__xvdFi";
export var gap2 = "ProviderLocalityShowStates__gap2__PzmWN";
export var gap3 = "ProviderLocalityShowStates__gap3__EDAUb";
export var gap4 = "ProviderLocalityShowStates__gap4__Zwhcz";
export var gap5 = "ProviderLocalityShowStates__gap5__s3EmN";
export var localitiesContainer = "ProviderLocalityShowStates__localitiesContainer__pQ6jc";
export var localitiesList = "ProviderLocalityShowStates__localitiesList__EEuWF";
export var providersList = "ProviderLocalityShowStates__providersList__HO5hA";
export var row = "ProviderLocalityShowStates__row__iOoQL";