// extracted by mini-css-extract-plugin
export var column = "ProviderLocalityShowCities__column__Udd74";
export var flex = "ProviderLocalityShowCities__flex__sMT4O";
export var flexColumn = "ProviderLocalityShowCities__flexColumn__bwI0x";
export var gap1 = "ProviderLocalityShowCities__gap1__bx6vH";
export var gap2 = "ProviderLocalityShowCities__gap2__I5dmU";
export var gap3 = "ProviderLocalityShowCities__gap3__DTi2f";
export var gap4 = "ProviderLocalityShowCities__gap4__xxDU7";
export var gap5 = "ProviderLocalityShowCities__gap5__kPNPj";
export var localitiesContainer = "ProviderLocalityShowCities__localitiesContainer__u1wTT";
export var localitiesList = "ProviderLocalityShowCities__localitiesList__F6W14";
export var providersList = "ProviderLocalityShowCities__providersList__dwUZV";
export var row = "ProviderLocalityShowCities__row__nOn57";