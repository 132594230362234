// extracted by mini-css-extract-plugin
export var buttonInactive = "ProviderLocalityViewButtons__buttonInactive__k9B7K";
export var buttonLeft = "ProviderLocalityViewButtons__buttonLeft__v2KYi";
export var buttonMiddle = "ProviderLocalityViewButtons__buttonMiddle__em8Qr";
export var buttonRight = "ProviderLocalityViewButtons__buttonRight__qQ7TI";
export var column = "ProviderLocalityViewButtons__column__L2fWI";
export var flex = "ProviderLocalityViewButtons__flex__WJKy6";
export var flexColumn = "ProviderLocalityViewButtons__flexColumn__hiDR2";
export var gap1 = "ProviderLocalityViewButtons__gap1__DbM11";
export var gap2 = "ProviderLocalityViewButtons__gap2__oNpIP";
export var gap3 = "ProviderLocalityViewButtons__gap3__G8coS";
export var gap4 = "ProviderLocalityViewButtons__gap4__mhPlq";
export var gap5 = "ProviderLocalityViewButtons__gap5__ArYHP";
export var row = "ProviderLocalityViewButtons__row__TlvRS";
export var wrapper = "ProviderLocalityViewButtons__wrapper__lYyHK";